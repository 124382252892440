@use "sass:color";

// @font-face {
//   font-family: "Montserrat";
//   font-weight: 400;
//   src: local("Montserrat"),
//     url(./lib/fonts/montserrat/Montserrat-Regular.ttf) format("truetype");
// }
// @font-face {
//   font-family: "Montserrat";
//   font-weight: 600;
//   src: local("Montserrat"),
//     url(./lib/fonts/montserrat/Montserrat-SemiBold.ttf) format("truetype");
// }
// @font-face {
//   font-family: "Montserrat";
//   font-weight: 700;
//   src: local("Montserrat"),
//     url(./lib/fonts/montserrat/Montserrat-Bold.ttf) format("truetype");
// }

// $primary: $grey-darker;
$primary: #010a44;
$scheme-main: #f5f8ff;
// $scheme-main: #46df6a;

// $text: $black-ter;
$text: #010a44;
$weight-semibold: 600;
$weight-bold: 700;

$link: $text;

$black-bis: $text;
$grey-darker: color.scale($text, $lightness: 10%);;
$grey-dark: color.scale($text, $lightness: 20%);;
$text-invert: color.invert($text);
// $border: $black-ter;
$border: #010a44;
// $border-hover: $black-bis;
$border-hover: color.scale($border, $lightness: -15%);
// $border-light: $grey-dark;
$border-light: color.scale($border, $lightness: 15%);
// $border-light-hover: $grey-darker;
$border-light-hover: color.scale($border, $lightness: 20%);

$navbar-tabs-height: 4.5rem;
