@import "./variables";

html {
  overscroll-behavior: contain;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  line-height: 1.5;
  color: $text;
  padding-bottom: 60px; // navbar
  background-color: $scheme-main;
  padding: 0;
  box-sizing: border-box;
  }
  
  input {
    box-sizing: border-box;
}

body {
  margin: 0;
}

label {
  display: block;
  font-weight: $weight-semibold;
  padding-bottom: .5rem;
}

.field:not(:last-child) {
  margin-bottom: 0.75rem;
}

.buttons {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  &.has-addons .button:not(:first-child) {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    // margin-right: -1px;
  }

  &.has-addons .button:not(:last-child) {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    margin-right: -1px;
  }
}

.button, .file-cta, .file-name, .pagination-ellipsis, .pagination-link, .pagination-next, .pagination-previous, .select select, .textarea {
  align-items: center;
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: none;
  display: inline-flex;
  font-size: 1rem;
  height: 2.5em;
  justify-content: flex-start;
  line-height: 1.5;
  padding: calc(0.5em - 1px) calc(0.75em - 1px);
  position: relative;
  vertical-align: top;
  background-color: #f5f8ff;
  border-color: #010a44;
  border-width: 1px;
  color: #363636;
  cursor: pointer;
  justify-content: center;
  padding: calc(0.5em - 1px) 1em;
  text-align: center;
  white-space: nowrap;
}

.input {
  align-items: center;
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: none;
  display: inline-flex;
  font-size: 1rem;
  justify-content: flex-start;
  line-height: 1.5;
  padding: calc(0.5em - 1px) calc(0.75em - 1px);
  position: relative;
  vertical-align: top;
  background-color: #f5f8ff;
  border-color: #010a44;
  border-width: 1px;
  color: #363636;
  cursor: pointer;
  justify-content: center;
  padding: calc(0.5em - 1px) 1em;
  text-align: center;
  white-space: nowrap;
  width: 100%;
}

.columns {
  display: flex;
  margin-left: -0.75rem;
  margin-right: -0.75rem;
  margin-top: -0.75rem;

  &:not(:last-child) {
    margin-bottom: .75rem;
  }
}

.column {
  display: block;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 0.75rem;
}

#root {
  max-width: 460px;
  margin: 0 auto;
}

.title,
.subtitle {
  width: 100%;
}

.field.is-grouped {
  display: flex;
  justify-content: flex-start;

  & >.label:not(:last-child) {
    flex-grow: 1;
    margin-bottom: 0;
    align-self: center;
  }
  .input {
    width: 0;
    min-width: 0;
    flex-grow: 1;
  }
}

.input,
.textarea {
  background-color: white;
}


.select {
  width: 100%;

  select {
    width: 100%;
    background-color: white;
  }
}

.button {
  &:hover {
    border-color: $border-hover;
  }

  &:focus,
  &.is-focused {
    border-color: $black-bis;

    &:not(:active) {
      box-shadow: none;
    }
  }

  &:active,
  &.is-active {
    background-color: $primary;
    color: $scheme-main;
  }

  &.is-primary {
    background-color: $primary;
    color: $scheme-main;
    border-color: transparent;
  }

  &.is-fullwidth {
    display: flex;
    width: 100%;
  }
}

.field {
  &.is-grouped {
    > .label:not(:last-child) {
      flex-grow: 1;
      margin-bottom: 0;
      align-self: center;
    }

    .input {
      width: 0;
      min-width: 0;
      flex-grow: 1;
    }
  }
}

.App {
  padding: 1rem 1rem calc(75px + 1rem) 1rem;
}

.navbar {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 30;
  justify-content: center;
  border-top: 1px solid hsl(0, 0%, 33%);
  background-color: $scheme-main;

  &-brand {
    display: flex;
    align-items: stretch;
    flex-shrink: 0;
  }

  &-brand,
  &-tabs {
    height: $navbar-tabs-height;
  }

  .navbar-item {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    line-height: 1;

    width: 25%;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    font-size: 0.8rem;
    line-height: 200%;

    svg {
      color: $text;
      stroke-width: 1;
    }

    &:hover,
    &:focus,
                                &:active,
                                &:visited {
      color: $text;
      background-color: transparent;
    }

    &.is-active {
      background-color: transparent;
      font-weight: $weight-bold;

      svg {
        stroke-width: 2;
      }
    }

    &.is-disabled {
      cursor: default;
      opacity: 0.33;
    }
  }
}

.is-connecting {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  height: 6px;
  background-color: $border-light;
}

.layout-center-v {
  display: flex;
  height: calc(100vh - #{$navbar-tabs-height});
  justify-content: center;
  align-items: center;
}
